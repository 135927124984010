import React, {useEffect, useState} from "react";
import {ref, listAll, getDownloadURL} from "firebase/storage";
import {storage} from "../firebase";

export default function Background() {

  const [url, setUrl] = useState([]);

  useEffect(() => {
    listAll(ref(storage, "garlands"))
    .then(res => res.items.forEach(item => getDownloadURL(item)
      .then(theUrl => setUrl(prev => [...prev, theUrl]))))
    .catch(err => console.log(err))
  }, [])

  return (
    <div>
      {url.map((imgUrl, i) => <img key={i} src={imgUrl} alt={`${imgUrl}`}/>)}
    </div>
  )
}
