import Background from "./background/background";
import ContactUs from "./contactus/contactus";
import {widthHeightCenter} from "./styles/cssclassnames";

function App() {
  return (
    <div className={widthHeightCenter}>
      <ContactUs/>
      <Background/>
    </div>
  );
}

export default App;
