import {initializeApp} from "firebase/app";
import {getStorage} from "firebase/storage";

const app = initializeApp({
  apiKey: "AIzaSyALWjrYJSb5M-bYIo1fiU4YLBKm_eyEiP4",
  authDomain: "kidsvaganza-888d1.firebaseapp.com",
  projectId: "kidsvaganza-888d1",
  storageBucket: "kidsvaganza-888d1.appspot.com",
  messagingSenderId: "361746838881",
  appId: "1:361746838881:web:31f6e998632a6017b2c120" 
});

export const storage = getStorage(app);

