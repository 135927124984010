import React from "react";
import {fixedRight} from "../styles/cssclassnames";

export default function ContactUs() {
  return (
    <div className={fixedRight}>
      <a href="https://wa.me/6281808172786" target="_blank">
        <h3>For booking, </h3>
        <h3>contact us at</h3> 
        <h3><b>0818 0817 2786</b></h3> 
      </a>
    </div>
  )
}
